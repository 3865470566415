import { gql } from '@apollo/client'

export type GraphPoint = {
  symbol: string
  timestamp: number
  value: number
}

const GRAPH_POINT_FIELDS = gql`
  fragment GraphPointFields on GraphPoint {
    symbol
    timestamp
    value
  }
`

export type LongRange = '24h' | '7d' | '30d' | '1y' | 'all'

export type PricesGraphPointsVars = {
  symbols: string[]
  range: LongRange
}

export type PricesGraphPointsData = {
  pricesGraphPoints: GraphPoint[]
}

export const PRICES_GRAPH_POINTS_QUERY = gql`
  ${GRAPH_POINT_FIELDS}
  query PricesGraphPoints(
    $symbols: [String!]!
    $range: String!
  ) {
    pricesGraphPoints(
      symbols: $symbols
      range: $range
    ) {
      ...GraphPointFields
    }
  }
`
