import * as React from 'react'

import { Box, Stack, Typography } from '@mui/material'

import {
  AppContainer,
  CompositionGraph,
  TickerPricesGraph,
} from '../components'
import { getGraphData } from '../services'

import type { Fund, GraphPoint } from '../queries'

const graphHeight = 280

const CompositionGraphWrapper = ({ fund }: { fund: Fund }) => {
  const dummyParent = {
    id: 'portfolio',
    parent: null,
    size: null,
  }
  const data = fund.assets
    .map((asset, index) => ({
      id: asset,
      parent: 'portfolio',
      size: fund.percentages[index],
    }))
    .filter((item) => item.size !== 0)

  return (
    <Box sx={{ marginTop: 2, height: graphHeight }}>
      <CompositionGraph
        totalQuoteAmount={100}
        data={[dummyParent, ...data]}
      />
    </Box>
  )
}

type TickerPricesGraphWrapperProps = {
  fund: Fund
  graphPoints?: GraphPoint[]
}

const TickerPricesGraphWrapper = ({
  fund,
  graphPoints,
}: TickerPricesGraphWrapperProps ) => {
  const graphData = getGraphData(graphPoints || [], fund.symbol)

  return (
    <Box sx={{ marginTop: 2, height: graphHeight }}>
      <TickerPricesGraph
        currency='USD'
        data={graphData.points}
        ath={graphData.ath}
        atl={graphData.atl}
      />
    </Box>
  )
}

type FundDisplayProps = {
  fund: Fund
  graphType: 'COMPOSITION' | 'TOTAL_AMOUNT'
  graphPoints?: GraphPoint[]
  onClick?: () => void
  children?: React.ReactNode
}

export const FundDisplay = ({
  fund,
  graphType,
  graphPoints,
  onClick,
  children,
}: FundDisplayProps) => (
  <AppContainer
    sx={{ p: 2, ...(onClick && { cursor: 'pointer' }) }}
    onClick={onClick}
  >
    <Stack
      direction='row'
      justifyContent='space-between'
    >
      <Typography variant='h4'>
        {fund.name}
      </Typography>
      <Stack direction='row'>
        {children}
      </Stack>
    </Stack>
    {graphType === 'COMPOSITION' ? (
      <CompositionGraphWrapper fund={fund} />
    ) : (
      <TickerPricesGraphWrapper
        fund={fund}
        graphPoints={graphPoints}
      />
    )}
  </AppContainer>
)
