import type { GraphPoint } from '../queries'

const selectGraphPoints = (graphPoints: GraphPoint[], symbol: string) =>
  graphPoints
    .filter((graphPoint) => graphPoint.symbol === symbol)
    .map((point) => ({ ...point, timestamp: point.timestamp * 1000 }))
    .sort((a, b) => a.timestamp - b.timestamp)

const getHighestValue = (graphPoints: GraphPoint[]) =>
  Math.max(...graphPoints.map((point) => point.value))

const getLowestValue = (graphPoints: GraphPoint[]) =>
  Math.min(...graphPoints.map((point) => point.value))

const getLastPrice = (graphPoints: GraphPoint[]) =>
  graphPoints[graphPoints.length - 1]?.value

const getLastTimestamp = (graphPoints: GraphPoint[]) =>
  graphPoints[graphPoints.length - 1]?.timestamp

const getVariation = (graphPoints: GraphPoint[]) => {
  const firstPrice = graphPoints[0]?.value

  if (!firstPrice) {
    return 0
  }

  return 100.0 * (getLastPrice(graphPoints) - firstPrice) / firstPrice
}

export type GraphData = {
  symbol: string
  points: GraphPoint[]
  ath: number
  atl: number
  lastPrice: number | undefined
  lastTimestamp: number | undefined
  variation: number
}

export const getGraphData = (graphPoints: GraphPoint[], symbol: string): GraphData => {
  const selectedGraphPoints = selectGraphPoints(graphPoints, symbol)

  return ({
    symbol,
    points: selectedGraphPoints,
    ath: getHighestValue(selectedGraphPoints),
    atl: getLowestValue(selectedGraphPoints),
    lastPrice: getLastPrice(selectedGraphPoints),
    lastTimestamp: getLastTimestamp(selectedGraphPoints),
    variation: getVariation(selectedGraphPoints),
  })
}
